<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <van-cell-group inset >
            <van-form>
                <van-field
                    v-model="email.password"
                    type="password"
                    name="password"
                    label="登录密码"
                    placeholder="请输入登录密码"
                    :rules="[{ required: true, message: '请输入登录密码' }]"
                />
                <van-field
                    v-model="email.email"
                    type="email"
                    name="email"
                    label="新邮箱"
                    placeholder="请输入新新邮箱"
                    :rules="[{ required: true, message: '请输入新新邮箱' }]"
                />
                <van-field
                    v-model="email.emailCode"
                    center
                    clearable
                    label="验证码"
                    placeholder="请输入邮箱验证码"
                >
                    <template #button>
                        <van-button
                            v-if="!sendEmailTime"
                            size="small"
                            type="info"
                            @click="sendEmail()"
                        >获取验证码</van-button>
                        <van-button
                            v-else
                            size="small"
                            type="info"
                            disabled
                        >重发({{ sendEmailTime }})</van-button>
                    </template>
                </van-field>
                <div class="sub_button">
                    <van-button round block type="primary" @click="submit">确 认</van-button>
                </div>
            </van-form>
        </van-cell-group>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'

import { email_erification_code_request,edit_eamil_request } from '@/network/user'

export default {
    name:'UserEmailComponent',
    data(){
        return {
            email: {
                password: '',
                email: '',
                emailCode: '',
            },
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
                fixed: false,   //固定
                placeholder: false  //等高占位
            },
            my_nav_bar_name: '修改邮箱',
            sendEmailTime: 0
        }
    },
    computed:{},
    methods:{
        sendEmail() {
            this.$store.commit('true_loading')
            this.sendEmailTime = 60
            email_erification_code_request(this.email)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('验证码已发送!')
                        const changeSendEmailTime = setInterval(() => {
                            if (this.sendEmailTime) {
                                this.sendEmailTime--
                            } else {
                                clearInterval(changeSendEmailTime)
                            }
                        }, 1000)
                    } else {
                        this.$toast.fail(s.msg)
                        this.sendEmailTime = 0
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.sendEmailTime = 0
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        submit() {
            this.$store.commit('true_loading')
            edit_eamil_request(this.email)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('修改成功!')
                        this.$router.go(-1);//返回上一层
                    } else {
                        this.$toast.fail(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        }
    },
    filters:{},
    props:{},
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        NavBarComponent,
        LoadingComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
</style>